import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

import "../components/css/page/questions.css"
import questions from "./../images/others/questions.png"

class Questions extends React.Component {

  render () {

    return (
      <Layout page="questions">
        <SEO title="⭐ 강의질문은 꼭 이렇게 보내주세요!" />
        <section id="questions">
          <h1>
            <span role="img" aria-label="">⭐ </span>
            강의질문은 꼭 이렇게 보내주세요!
          </h1>
          <p>
            각 강의 페이지마다 하단에 아래와 같이 질문에 대한 안내사항이 있습니다. <br/>
            반드시 이를 확인하시고, <b>안내에 따라 메일로</b> 질문을 보내주세요.
          </p>
          <img src={questions} alt="questions" />
          <p>
            유튜브 댓글이나 인프런 등의 질문 기능으로 질문주시면
          </p>
          <p>
            제가 당일 확인을 하지 못하거나 답이 늦어지는 경우가 많습니다. <br/>
            <i>(메일 앱에 비해, 폰이나 태블릿에서 바로 확인하고 답장하기 어렵습니다. 낮부터 육아를 하므로, 컴퓨터 앞에 앉아 있는 시간이 적습니다. 😭)</i>
          </p>
          <p>
            질문시 꼭 확인 부탁드리겠습니다. 감사합니다.
          </p>
        </section>
      </Layout>
    )
  }
}

export default Questions
